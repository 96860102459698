<template>
  <section class="blog">
    <!-- <Loader :loading="loading" /> -->
    <template v-if="!loading">
      <div class="blog__header">
        <img class="blog__header-image" :src="blog.image" alt="blog image" />
        <div class="blog__header-content">
          <router-link class="blog__tag blog__link text-base" to="/library/blog">Blog</router-link>
          <h1 class="blog__header-title text-title">{{ blog.title }}</h1>
          <div class="blog__info">
            <p class="blog__info-author text-description">by {{ blog.author }}</p>
            <p class="blog__info-date text-description">
              {{ $moment(blog.date).format("MMM D, YYYY") }}
            </p>
          </div>
          <div class="blog__tags">
            <router-link
              class="blog__tag text-base"
              v-for="tag in blog.tags"
              :key="`tag-${tag.id}`"
              :to="{ path: '', query: { search_tag: tag.title } }"
            >
              {{ tag.title }}
            </router-link>
          </div>
        </div>
      </div>
      <Subscribe text="updates" />
      <div class="blog__container">
        <div class="blog__share">
          <p class="blog__info-author text-description">by {{ blog.author }}</p>
          <div class="blog__share-inner">
            <p class="blog__share-text text-description">share:</p>
            <ShareNetwork
              class="blog__social-link"
              network="facebook"
              :url="url"
              :title="blog.title"
            >
              <IconFacebook class="blog__social-icon" color="#000" />
            </ShareNetwork>
            <ShareNetwork
              class="blog__social-link"
              network="twitter"
              :url="url"
              :title="blog.title"
            >
              <IconTwitter class="blog__social-icon" color="#000" />
            </ShareNetwork>
          </div>
        </div>
        <div class="blog__contant collect-editor" v-html="blog.content"></div>
        <div class="blog__share">
          <div class="blog__share-inner">
            <p class="blog__share-text text-description">share:</p>
            <ShareNetwork
              class="blog__social-link"
              network="facebook"
              :url="url"
              :title="blog.title"
            >
              <IconFacebook class="blog__social-icon" color="#000" />
            </ShareNetwork>
            <ShareNetwork
              class="blog__social-link"
              network="twitter"
              :url="url"
              :title="blog.title"
            >
              <IconTwitter class="blog__social-icon" color="#000" />
            </ShareNetwork>
          </div>
        </div>
      </div>
      <div class="blog__footer">
        <p class="text-base blog__footer-text" v-if="nextBlog">
          Next Blog Post: {{ nextBlog.title }}
        </p>
        <div class="blog__footer-container">
          <BaseButton
            v-if="nextBlog"
            class="blog__footer-button button--black-grey button--ellipse button--lg"
            text="Open Next Blog"
            @click="openNextBlog"
          />
          <BaseButton
            class="blog__footer-button button--outline button--ellipse button--lg"
            :class="{ 'blog__footer-button_one': !nextBlog }"
            text="Return To Top Of Page"
            @click="backToTop"
          />
          <div class="blog__footer-line"></div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { scroller } from "vue-scrollto/src/scrollTo";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Subscribe from "@/components/Subscribe.vue";
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconYoutube from "@/components/icons/IconYoutube.vue";
import IconTwitter from "@/components/icons/IconTwitter.vue";
import IconFacebook from "@/components/icons/IconFacebook.vue";
import IconInstagram from "@/components/icons/IconInstagram.vue";

export default {
  components: {
    Subscribe,
    Loader,
    BaseButton,
    IconYoutube,
    IconTwitter,
    IconFacebook,
    IconInstagram,
  },
  data() {
    return {
      loading: false,
      url: `${window.location}`,
      pageTitle: "GH - Blog",
    };
  },
  head: {
    title: function () {
      return {
        inner: this.pageTitle,
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  computed: {
    ...mapGetters("publicBlogs", {
      blog: "getBlog",
      nextBlog: "getNextBlog",
    }),
  },
  async created() {
    this.loading = true;
    const { blogSlug } = this.$route.params;
    await this.apiGetBlog(blogSlug);
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Library",
        to: "/library",
      },
      {
        title: "Blog",
        to: "/library/blog",
      },
      {
        title: `${this.blog.title}`,
        to: `/library/blog/${blogSlug}`,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.pageTitle = `GH - ${this.blog.title}`;
    this.$emit("updateHead");
    this.loading = false;
  },
  watch: {
    "$route.path": async function () {
      this.loading = true;
      const { blogSlug } = this.$route.params;
      await this.apiGetBlog(blogSlug);
      const breadcrumbs = [
        {
          title: "Home",
          to: "/",
        },
        {
          title: "Library",
          to: "/library",
        },
        {
          title: "Blog",
          to: "/library/blog",
        },
        {
          title: `${this.blog.title}`,
          to: `/library/blog/${blogSlug}`,
        },
      ];
      this.setBreadcrumbs(breadcrumbs);
      this.pageTitle = `GH - ${this.blog.title}`;
      this.$emit("updateHead");
      this.loading = false;
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicBlogs", ["apiGetBlog"]),
    openNextBlog() {
      this.$router.push({ path: `/library/blog/${this.nextBlog.slug}` });
    },
    backToTop() {
      const firstScrollTo = scroller();
      firstScrollTo(".header_breadcrumbs");
    },
  },
};
</script>

<style lang="scss" scoped>
.blog {
  min-width: 100vw;
  min-height: 100vh;
  background-color: $white;

  &__header {
    position: relative;
    width: 100vw;
    height: 80vh;
    &-image {
      object-fit: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      @media (max-width: $xxs) {
        width: auto;
        max-width: none;
      }
    }
    &-content {
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(0, 0, 0, 0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 9rem 3rem 16.4rem;
      @media (max-width: $xxs) {
        padding: 0 25px 9rem 25px;
      }
    }
    &-title {
      max-width: 98rem;
      @media (max-width: $xxs) {
        font-size: 3.7rem;
        line-height: 1.2;
      }
    }
    &-button {
      margin-top: 35px;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 7.6rem;
    @media (max-width: $xxs) {
      margin: 1rem 0;
    }
    &-author {
      margin-right: 15px;
      font-weight: 600;
      line-height: 2.44;
    }
    &-date {
      font-weight: 600;
      line-height: 2.44;
    }
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
  }
  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 0 25px;
    height: 4rem;
    width: max-content;
    border-radius: 34px;
    border: 1px solid $white;
    min-width: 11rem;
    margin: 0 10px 10px 0;
    text-decoration: none;
    @media (max-width: $xxs) {
      min-width: min-content;
    }
  }
  &__link {
    font-size: 1.7rem;
    height: 4.5rem;
    min-width: 12.6rem;
    margin-bottom: 4rem;
    text-decoration: none;
    @media (max-width: $xxs) {
      margin-bottom: 2.6rem;
      min-width: 11rem;
    }
  }
  &__container {
    padding: 7.5rem 16.4rem 11.5rem;
    @media (max-width: $xxs) {
      padding: 5rem 25px 7rem;
    }
  }
  &__contant {
    padding: 3.5rem 11.6rem 2rem;
    @media (max-width: $xxs) {
      padding: 4.5rem 0 2rem;
    }
  }
  &__footer {
    width: 100%;
    padding: 0 0 9rem 39rem;
    @media (max-width: $xxs) {
      padding: 0 0 7rem 25px;
    }
    &-container {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 5rem;
      @media (max-width: $xxs) {
        margin-top: 3rem;
        flex-wrap: wrap;
      }
    }
    &-text {
      color: $black;
      padding-right: 25px;
      @media (max-width: $xxs) {
      }
    }
    &-button {
      min-width: 22rem;
      width: max-content;
      flex-shrink: 0;
      padding: 0 30px;
      &:first-of-type {
        margin-right: 15px;
        @media (max-width: $xxs) {
          margin-bottom: 15px;
        }
      }
      &_one {
        &:first-of-type {
          margin-right: 0;
        }
      }
      @media (max-width: $xxs) {
        min-width: 26rem;
        padding: 0 12px;
      }
    }
    &-line {
      height: 1px;
      min-width: 2.9rem;
      flex-grow: 1;
      background-color: $black;
    }
  }
  &__share {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 11.6rem;
    @media (max-width: $xxs) {
      padding: 0;
    }
    & > .blog__info-author {
      margin-right: 6.4rem;
      color: $black;
    }
    &-inner {
      display: flex;
      align-items: center;
    }
    &-text {
      color: $black;
      font-weight: 600;
      line-height: 2.44;
    }
  }
  &__social {
    &-link {
      margin-left: 30px;
      @media (max-width: $xxs) {
        margin-left: 25px;
      }
      @media (max-width: $xxxs) {
        margin-left: 20px;
      }
    }
    &-icon {
      width: 30px;
      height: 25px;
      @media (max-width: $xxs) {
        width: 25px;
        height: 20px;
      }
    }
  }
}
</style>
